<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <!-- <div class="head">
      <div>
        <V-ArticleNav :navList="navList"></V-ArticleNav>
      </div>
    </div>-->
    <!-- 内容 -->
    <div class="center">
      <!-- 内容-左 -->
      <div class="main">
        <!-- 关键词 -->

        <div class="keyword">
          <span class="dels">关键字 ></span>
          <span class="delsi">
            <i></i>
            <span>{{ searchWord }}</span>
          </span>
        </div>
        <div>
          <div class="left">
            <!-- 关键词主题文章 -->
            <!-- <div class="theme"> -->
            <!-- 说明 -->
            <!-- <div class="intro">
                本主题为 智荟网的{{
                  searchWord
                }}专题，内容全部来自 智荟网精心选择与{{
                  searchWord
                }}相关的最近资讯， 智荟网读 懂智能与未来，拥有{{
                  searchWord
                }}资讯的信息，在这里你能看到未来的世界。
            </div>-->
            <!-- 主题列表 -->
            <!-- <div>
                <div
                  class="one-theme"
                  v-for="oneTheme in themeList"
                  :key="oneTheme.id"
                >
                  <V-OneTheme
                    :imgUrl="oneTheme.img"
                    :id="oneTheme.id"
                    :title="oneTheme.title"
                    :imgHeight="imgHeight"
                    :fontSize="fontSize"
                  ></V-OneTheme>
                </div>
            </div>-->
            <!-- </div> -->
            <!-- 最新文章 -->
            <div class="newest-article">
              <!-- <div>最新文章</div> -->
              <div class="content">
                <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
              </div>
              <div class="page-nav">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :hide-on-single-page="hidePageNav"
                  :total="totlePage"
                  :page-size="pageSize"
                  @prev-click="prevPage"
                  @next-click="nextPage"
                  @current-change="currentPage"
                ></el-pagination>
              </div>
            </div>
          </div>
          <!-- 内容-右 -->
          <!-- <div class="right"> -->
          <!-- 相关文章 -->
          <!-- <div class="relation-article"> -->
          <!-- <div>相关文章</div> -->
          <!-- <div
                class="one"
                v-for="rArticle in relationArticle"
                :key="rArticle.id"
              >
                <V-OneRelationArticle
                  :article="rArticle"
                ></V-OneRelationArticle>
          </div>-->
          <!-- </div> -->
          <!-- 右侧热门关键字 -->
          <!-- <div> -->
          <!-- <V-HotSearchWord :hotSearchWord="hotSearchWord"></V-HotSearchWord> -->
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- 底部热门关键字 -->
    <div class="foot">
      <div>
        <!-- <V-HotLabel :hotWords="hotWords"></V-HotLabel> -->
      </div>
    </div>
  </div>
</template>

<script>
import OneRelationArticle from "@/components/linfo-rpic/index.vue";
import HotSearchWord from "@/components/hot-search-word/index.vue";
import ArticleNav from "../../components/article-nav/index.vue";
import OneTheme from "../../components/one-theme/index.vue";
import ArticleList from "@/components/one-article/type-one/index.vue";
import HotLabel from "@/components/hot-label/index.vue";
import { showTimeFormat } from "@/js/common/index.js";
import { DELETE, QUERY } from "@/services/dao.js";

export default {
  data() {
    return {
      hotSearchWord: [],
      relationArticle: [],
      searchWord: "",
      hidePageNav: true,
      pageNum: 0,
      pageSize: 12,
      totlePage: 0,
      hotWords: [],
      newarticlelist: [],
      navList: [],
      themeList: [],
      imgHeight: "150px",
      fontSize: "14px"
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.searchWord != from.query.searchWord) {
        this.init(); //重新加载数据
      }
    }
  },

  methods: {
    async init() {
      this.searchWord = this.$route.query.searchWord;
      // this.getNav();
      // 获取最新文章列表
      this.getNewestArticle();
      //获取主题
      // this.getTheme();
      //获取相关文章
      // this.getRelationArticle();
      //获取热门搜索
      // this.getHotSearch();
      //获取热门关键词
      // this.getHotWord();
    },
    //填充导航栏数据
    // getNav() {
    //   this.navList.length = 0;
    //   // this.navList.push({ word: "热门搜索" });
    //   this.navList.push({ word: this.searchWord });
    // },
    //获取主题
    // async getTheme() {
    //   let info = await QUERY(
    //     "post",
    //     "",
    //     'theme(limit: 3, where: {title: {_like: "%' +
    //       this.searchWord +
    //       '%"}}) {    createTime    id    img    title    content  }'
    //   );
    //   this.themeList = info.data.theme;
    // },
    //获取相关文章
    // async getRelationArticle() {
    //   this.relationArticle.splice(0, this.relationArticle.length);
    //   let info = await QUERY(
    //     "post",
    //     "",
    //     'Article(limit: 6,where: {title: {_like: "%' +
    //       this.searchWord +
    //       '%"}}) {    id    img    title    type  }'
    //   );
    //   for (let i = 0; i < info.data.Article.length; i++) {
    //     this.relationArticle.push({
    //       id: info.data.Article[i].id,
    //       info: info.data.Article[i].title,
    //       img: info.data.Article[i].img,
    //     });
    //   }
    // },
    //获取热门搜索
    // async getHotSearch() {
    //   this.hotSearchWord.splice(0, this.hotSearchWord.length);
    //   let info = await QUERY(
    //     "post",
    //     "",
    //     "HotSearchKeyword(limit: 20) {    createTime    id    timesCited    word  }"
    //   );
    //   for (let i = 0; i < info.data.HotSearchKeyword.length; i++) {
    //     this.hotSearchWord.push(info.data.HotSearchKeyword[i].word);
    //   }
    // },
    //获取最新文章
    async getNewestArticle() {
      //alert(this.searchWord)
      // console.log(this.info)
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ',  where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}) {   id userid  imge createTime        commentNum    collectNum        authorName  coverImg       img      intro    keyword    praiseNum          title      hotNum     }  Article_aggregate (where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}){    aggregate {      count    }  }'
      );
      console.log(this.info);
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collectNum: info.data.Article[i].collectNum,
          commentNum: info.data.Article[i].commentNum,
          image:
            info.data.Article[i].coverImg.indexOf("https://") != -1 ||
            info.data.Article[i].coverImg.indexOf("http://") != -1
              ? info.data.Article[i].coverImg
              : this.api.LoginURL.concat(info.data.Article[i].coverImg),
          // image: this.api.LoginURL.concat(info.data.Article[i].coverImg ),
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          keyword: info.data.Article[i].keyword,
          imge: this.api.LoginURL.concat(info.data.Article[i].imge),
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
    },
    //获取热门关键词
    // async getHotWord() {
    //   let info = await QUERY(
    //     "post",
    //     "",
    //     "keyword(limit: 50) {        id        word  }"
    //   );
    //   this.hotWords.splice(0, this.hotWords.length);
    //   for (let i = 0; i < info.data.keyword.length; i++) {
    //     this.hotWords.push(info.data.keyword[i]);
    //   }
    // },
    //前一页
    async prevPage(val) {
      this.pageNum = val - 1;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ',  where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}) {    id    createTime    content userid  imge   concernNum    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme_id    title    type  }  Article_aggregate (where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}){    aggregate {      count    }  }'
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: info.data.Article[i].createTime,
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image: info.data.Article[i].img,
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          concernNum: info.data.Article[i].concernNum,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge,
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
    },
    //下一页
    async nextPage(val) {
      this.pageNum = val - 1;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ',  where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}) {    id    createTime userid  imge   content    concernNum    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme_id    title    type  }  Article_aggregate (where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}){    aggregate {      count    }  }'
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: info.data.Article[i].createTime,
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image: info.data.Article[i].img,
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          concernNum: info.data.Article[i].concernNum,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge,
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;

      console.log("nextPage" + val);
    },
    //当前页
    async currentPage(val) {
      this.pageNum = val - 1;
      let info = await QUERY(
        "post",
        "",
        "  Article(limit: " +
          this.pageSize +
          ", offset: " +
          this.pageSize * this.pageNum +
          ',  where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}) {    id userid  imge   createTime    content    concernNum    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme_id    title    type  }  Article_aggregate (where: {keyword: {_like: "%' +
          this.searchWord +
          '%"}}){    aggregate {      count    }  }'
      );
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: info.data.Article[i].createTime,
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image: info.data.Article[i].img,
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          concernNum: info.data.Article[i].concernNum,
          keyword: info.data.Article[i].keyword,
          imge: info.data.Article[i].imge,
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;

      console.log("currentPage" + val);
    }
  },
  components: {
    // "V-ArticleNav": ArticleNav,
    // "V-OneTheme": OneTheme,
    "V-ArticleList": ArticleList
    // "V-HotLabel": HotLabel,
    // "V-OneRelationArticle": OneRelationArticle,
    // "V-HotSearchWord": HotSearchWord,
  }
};
</script>

<style scoped>
.head {
  background: rgba(235, 235, 235, 1);
  padding: 26px;
  text-align: center;
}
.center {
  width: 80%;
  /* text-align: center; */
  background: #f7f7f7;
  /* border: 1px solid hotpink; */
  margin: auto;
}
.main {
  /* display: inline-block; */
  width: 90%;
  min-height: 500px;
  /* text-align: left; */
  margin: auto;
}
.dels {
  font-size: 12px;
  margin-right: 3px;
}
.main .left,
.main .right {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-left: 20px;
}
.main .right > div {
  padding: 16px;
}
.main .right .relation-article {
  background: rgba(255, 255, 255, 1);
}
.main .right .relation-article .one {
  padding: 10px 0;
  border-top: 1px dashed rgba(172, 172, 172, 1);
  margin-top: 10px;
  height: 80px;
}
.main .keyword {
  padding: 10px 0 20px 0;
}
.main .left {
  width: 100%;
  margin: auto;
}
.main .theme {
  padding: 18px;
  background: rgba(255, 255, 255, 1);
}
.main .theme > .intro {
  padding-bottom: 20px;
}
.main .newest-article {
  margin-top: 30px;
  /* border: 1px solid forestgreen; */
}
.main .theme .one-theme:not(:first-of-type) {
  margin-left: 15px;
}
.main .theme .one-theme {
  width: 240px;
  display: inline-block;
}
.delsi {
  display: inline-block;
  background: rgba(170, 170, 170, 1);
  border: 1px solid rgba(170, 170, 170, 1);
  border-radius: 22px;
  text-align: center;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  padding: 2px 8px;
  /* height: 30px; */
}
.main .keyword span i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 740px;
  background-position-y: -34px;
}
.main .keyword span > * {
  vertical-align: middle;
}
.foot {
  text-align: center;
}
.foot > div,
.head > div {
  text-align: left;
  display: inline-block;
  width: 1150px;
}

/* 分页按钮样式 */
.el-pagination button,
.el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 32px;
  height: 32px;
  border: 2px solid rgba(155, 170, 182, 1);
  border-radius: 50% !important;
  background-color: rgba(237, 237, 237, 0);
  color: #606266;
}
.el-pagination {
  width: 100%;
  height: 48px;
  background: rgba(237, 237, 237, 1);
  position: relative;
  padding-top: 20px;
  text-align: center;
}
.el-pagination .btn-prev {
  position: absolute;
  left: 0px;
}
.el-pagination .btn-next {
  position: absolute;
  right: 0px;
}
.el-pagination button i {
  width: 100%;
  height: 100%;
}
.el-pagination.is-background .el-pager li {
  width: 32px;
  height: 32px;
  background-color: rgba(237, 237, 237, 0);
  border: 2px solid rgba(237, 237, 237, 0);
  border-radius: 50% !important;
}
.el-pagination.is-background .el-pager .number:not(.active):hover {
  width: 32px;
  height: 32px;
  border: 2px solid rgb(238, 91, 46);
  border-radius: 50% !important;
  background-color: white;
  color: rgb(238, 91, 46) !important;
}
.el-icon-arrow-left:before,
.el-icon-arrow-right:before {
  content: " ";
}
.el-pagination .btn-prev .el-icon {
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 759px;
  background-position-y: -564px;
}
.el-pagination .btn-next .el-icon {
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 718px;
  background-position-y: -564px;
}
.el-pagination button:hover {
  width: 32px;
  height: 32px;
  border: 2px solid rgb(238, 91, 46);
  border-radius: 50% !important;
  background-color: rgba(237, 237, 237, 0);
  color: #606266;
}
.el-pagination .btn-next:hover .el-icon {
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 671px;
  background-position-y: -564px;
}
.el-pagination .btn-prev:hover .el-icon {
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 667px;
  background-position-y: -604px;
}
.content {
  width: 100%;
  display: inline-block;
  /* border: 1px solid red; */
}
</style>
