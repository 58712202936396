<template>
  <div class="serKesy-list">
    <div class="rComTitle">
      热门搜索
    </div>
    <div >
      <!-- <span class="list "
        v-for="(word, index) in hotSearchWord"
        :key="index"
        >
        <span class="keyy"  @click="goToKeyWordPage(word)">{{ word }}</span>
      </span> -->
      <p v-for="(word, index) in hotSearchWord"
        :key="index"
        style="display:inline-block;">
        <span class="list ">
          <span class="keyy" style="cursor:pointer;" @click="goToKeyWordPage(word)">{{ word }}</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hotSearchWord: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToKeyWordPage(word) {
      this.$router.push({
        path: "/keywordArticle",
        query: {
          searchWord: word,
        },
      });
    },
  },
};
</script>

<style>
.rComTitle {
  display: inline-block;
  padding-top: 28px;
  padding-left:3px;
  font-size: 16px;
  font-weight: bold;
  color: #31424e;
  line-height: 1;
  padding-bottom: 12px;
}
.serKesy-list{
  width: 100%;
  /* border: 1px solid firebrick; */
  display: inline-block;
  word-break:break-all;
  padding-bottom: 10px;
}
.list{
  word-break:break-all;
}
.lph-right .rComTitle,
.serKesy-list .rComTitle,
.article-right .rComTitle {
  padding-top: 28px;
  font-size: 16px;
  font-weight: bold;
  color: #31424e;
  line-height: 1;
  padding-bottom: 12px;
}
.clr {
  zoom: 1;
}
.keyy{
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  /* margin: 5px 15px 15px 3px; */
  padding: 0 15px;
  background: #fff;
  color: #5e6b73;
  font-size: 14px;
  border: 1px solid #e8ecef;
  border-radius: 16px;
  -webkit-border-radius: 16px;
}
a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
/* .serKesy-list .list a {
  float: left;
  margin: 0 15px 15px 0;
  padding: 0 15px;
  background: #fff;
  color: #5e6b73;
  font-size: 14px;

  border: 1px solid #e8ecef;
  border-radius: 16px;
  -webkit-border-radius: 16px;
} */
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
</style>
