<template>
    <div class="uppic-downtext" @click="goThemeDetail()">
        <div class="img" :style="{height:imgHeight}">
            <img :src="imgUrl" alt="" />
        </div>
        <div class="title"  :style="{fontSize:fontSize}">
            {{title}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        imgUrl:{
            type:String,
            required:true
        },
        title:{
            type:String,
            required:true
        },
        id:{
            type:Number,
            required:true
        },
        imgHeight:{
            type:String,
            required:true
        },
        fontSize:{
            type:String,
            required:true
        }

    },
    methods:{
        //打开主题详情
        goToArticleDetail(){
            console.log(this.id);
            this.$router.push({path:'/themeDetail', query: {
                id: this.id
            }});
            console.log("文章id"+this.id);
        }
    }
}
</script>

<style scoped>
    .uppic-downtext{
        width: 100%;
        height: 100%;
    }
    .uppic-downtext .img {
        min-height: 80px;
    }
    .uppic-downtext .img img{
        height: 100%;
        width: 100%;
    }
   .uppic-downtext .title{
       margin-top: 8px;
    }
</style>