<template>
    <div class="hot-keyword">
        <div class="title">热门关键字</div>
        <div class="content">
            <span @click="goToKeyWordPage(word.word)" v-for="word in hotWords" :key="word.id">{{word.word}}</span>
        </div>
    </div>        
</template>

<script>
export default {
    props:{
        hotWords:{
            type:Array,
            required:true
        }
    },
    methods:{
        //打开文章详情
        goToKeyWordPage(word){
            this.$router.push({path:'/keywordArticle', query: {
                 searchWord: word
             }});
        }
    }

}
</script>

<style scoped>
.hot-keyword{
         display: inline-block;
         height: 185px;
         width: 900px;
        font-size:14px;
        font-family:Microsoft YaHei;
        font-weight:300;
        color:rgba(99,99,99,1);
        line-height:34px;
        text-align: left;
     }
.hot-keyword .content span{
          margin-right: 15px;
          display: inline-block;
      }
.hot-keyword .content span:hover{
        margin-right: 15px;
        display: inline-block;
        cursor: pointer;
        color: rgba(253,110,5,1);
 }      
</style>