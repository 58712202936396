<template>
<!-- 左文右图 -->
    <div style="height:100%;width:100%;cursor: pointer;">
        <div class="artic-brief" @click="seethem(article.id)">
            <div class="info">
                <span> {{article.info}}</span>
            </div>
            <div class="img">
                <img :src="article.img" alt="" width="100%" height="100%" >
            </div>
        </div>
   </div>
</template>
<script>
export default {
    props:{
        article:{
            type:Object,
            required:true
        }
    }
    ,
    methods:{
        //查看主题详情
        seethem(id){
         this.$router.push({path:'/themeDetail', query: {
            id: id
        }});
        }
    }
}
</script>

<style scoped>
    .artic-brief {
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    .artic-brief .info{
        display: inline-block;
        height: 100%;
        width: 60%;
        vertical-align: top;
        position:relative;
    }
    .artic-brief .info span{
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color:#5e6b73;
        font-size:14px;
    }
    .artic-brief .img{
        display: inline-block;
        width: 40%;
        height: 70%;
        line-height: 70%;
        padding-top: 6%;
        padding-bottom: 10%;
        /* text-align: center; */
    }
    .artic-brief .img img{
        height: 100%;
        width: 100%;
        display: block;
    }
</style>